<template>
  <b-card>
    <b-card-text>
      <b-row>
        <b-col
          cols="12"
        >
          <h3>Escolha o método de importação disponível:</h3>
        </b-col>

        <b-col cols="12">
          <b-form-radio
            v-model="format"
            value="usuario;senha"
            class="custom-control-primary mt-1 mb-1"
          >
            USUARIO;SENHA
          </b-form-radio>

          <b-form-radio
            v-model="format"
            value="usuario;senha;email;email_senha"
            class="custom-control-primary mb-1"
          >
            USUARIO;SENHA;EMAIL;SENHA_EMAIL
          </b-form-radio>

          <b-form-radio
            v-model="format"
            :value="'usuario\\nsenha\\n'"
            class="custom-control-primary mb-1"
          >
            Formato SC.Robot
          </b-form-radio>

          <b-form-radio
            v-model="format"
            value="usuario senha"
            class="custom-control-primary"
          >
            Formato NextGen
          </b-form-radio>
        </b-col>

        <b-col
          cols="12"
          class="mt-2"
        >
          <p class="lead">
            As informação são sempre separadas por por (ponto e virgula [;]).
            Exemplo: Caso você queira adicionar apenas usuario(usuario1) e senha(senha1) você pode digitar: usuario1;senha1
            Cada linha deve conter uma conta com usuário e senha
          </p>
        </b-col>

        <b-col cols="12">
          <b-card
            class="border"
            no-body
          >
            <pre
              style="overflow: hidden; white-space: pre-wrap; font-size: 14px"
              v-html="exampleOfFormat"
            />
          </b-card>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <span class="lead">
            O exemplo acima ira importar 3 contas com usuários [usuario, usuario2, usuario3] e as senhas [senha, senha2, senha3], para suas respectivas contas. Ou seja, 'usuario2' tem como senha a palavra 'senha2'
          </span>
        </b-col>

        <b-col
          cols="12"
          class="mt-1 mb-1"
        >
          <b-form-textarea
            id="textarea-default"
            v-model="accountText"
            placeholder="Digite suas contas aqui"
            rows="3"
          />
        </b-col>

        <b-col cols="12">
          <b-button
            variant="relief-primary"
            :disabled="loading"
            @click.stop="importAccounts"
          >
            <span v-if="!loading">Importar contas</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>

import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import IndexMixin from './IndexMixin'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BButton,
    BSpinner
  },
  mixins: [IndexMixin]
}
</script>

<style lang="scss" scoped>
pre {
  min-height: auto;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
