export default {
  data: () => ({
    loading: false,
    format: 'usuario;senha',
    accountText: '',
    items: []
  }),
  computed: {
    exampleOfFormat () {
      let response = ''

      if (this.format === 'usuario;senha') {
        response = 'usuario;senha<br>usuario1;senha1<br>usuario2;senha2'
      }

      if (this.format === 'usuario;senha;email;email_senha') {
        response = 'usuario;senha;email;email_senha<br>usuario1;senha1;email1;email_senha1<br>usuario2;senha2;email2;email_senha2'
      }

      if (this.format === 'usuario\\nsenha\\n') {
        response = 'usuario<br>senha<br><br>usuario<br>senha<br>'
      }

      if (this.format === 'usuario senha') {
        response = 'usuario senha<br>usuario1 senha1<br>usuario2 senha2'
      }

      return response
    }
  },
  methods: {
    async importAccounts () {
      this.loading = true
      await this.getAccountDataFormated()
        .then(res => {
          this.items = res
        })
      const url = '/user/controle_infinity/accounts/import'
      const params = {
        accounts: this.items
      }

      await this.$http.post(url, params)
        .then(() => {
          // show message success in swal
          this.$swal({
            type: 'success',
            title: 'Importação realizada com sucesso!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            title: 'Erro ao importar contas!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getAccountDataFormated () {
      const data = this.accountText.split('\n')

      if (this.format === 'usuario;senha') {
        return data.map(item => {
          const account = item.split(';')

          return {
            username: account[0],
            password: account[1],
            email: null,
            email_password: null
          }
        })
      }

      if (this.format === 'usuario;senha;email;email_senha') {
        return data.map(item => {
          const account = item.split(';')

          return {
            username: account[0],
            password: account[1],
            email: account[2],
            email_password: account[3]
          }
        })
      }

      if (this.format === 'usuario\\nsenha\\n') {
        const dataSCRobot = this.accountText.split('\n\n')
        return dataSCRobot.map(item => {
          const account = item.split('\n')

          return {
            username: account[0],
            password: account[1],
            email: null,
            email_password: null
          }
        })
      }

      if (this.format === 'usuario senha') {
        return data.map(item => {
          const account = item.split(' ')

          return {
            username: account[0],
            password: account[1],
            email: null,
            email_password: null
          }
        })
      }
    }
  }
}
